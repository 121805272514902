<template>
  <v-card class="card text-center">
    <h3 class="card-text mb-8">Set your daily budget and payment card details.</h3>
    <v-radio-group v-model="setBudget">
      <div class="d-flex align-center">
        <v-radio :value="true" />
        <h4 class="mb-2">Select recommended budget option</h4>
      </div>
      <div
        :class="!setBudget ? 'd-none' : ''"
        class="my-3 pl-10"
        style="height: auto; background-color: #f9f9f9"
      >
        <v-row class="d-flex">
          <v-col md="5" sm="5">
            <v-radio-group v-model="checkPeriod">
              <v-radio
                v-for="(item, ind) in budgetRecommendations"
                :key="ind"
                @click="handleClickPeriod(item)"
                :value="item.period"
              >
                <template v-slot:label>
                  <span
                    style="
                      font-size: 15px !important;
                      color: #000000 !important;
                    "
                    class="font-family-raleway-medium"
                  >
                    {{ currencies[item.currency] }}
                    <span
                      class="font-family-raleway-bold"
                      style="font-size: 17px"
                    >
                      {{ item.amout }}
                    </span>
                    / {{ periods[item.period] }}
                  </span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
          <v-col class="mt-5" md="7" sm="7">
            <li style="font-size: 18px; font-weight: normal !important">
              <strong>
                <span class="font-family-raleway-bold">$300</span> monthly max
              </strong>
            </li>
            <li style="font-size: 18px; font-weight: normal !important">
              <strong>
                Get an estimate
                <span class="font-family-raleway-bold">300-500</span> ad clicks
                per month
              </strong>
            </li>
          </v-col>
        </v-row>
      </div>
      <div class="d-flex align-center">
        <v-radio :value="false" />
        <h4 class="mb-2">Set own budget</h4>
      </div>
      <v-row v-if="!setBudget">
        <v-col class="mt-2 d-flex" cols="12" md="6">
          <v-text-field
            v-model="budgetOwn.amout"
            class="input-border font-family-raleway-medium"
            style="font-size: 17px"
            type="number"
            height="60"
            outlined
            dense
          >
            <template v-slot:append>
              <div class="d-flex align-center" style="font-size: 18px">
                <strong class="mt-3 mr-2">
                  {{ currencies[budgetOwn.currency] }}
                </strong>
                <v-select
                  v-model="budgetOwn.period"
                  :items="periodItems"
                  dense
                  hide-details
                  item-text="value"
                  item-value="key"
                  style="width: 80px"
                  @input="handleChangeBudget"
                ></v-select>
              </div>
            </template>
          </v-text-field>
        </v-col>
        <v-col class="mt-2" cols="12" md="6">
          <ul class="font-family-raleway-bold">
            <li><strong>$300 </strong> monthly max</li>
            <li>
              Get an estimate <strong>300-500</strong> ad clicks per month
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-radio-group>
    <v-row>
      <v-col
        class="align-center d-flex pl-10 mt-4 font-family-raleway-medium"
        cols="12"
      >
        <v-btn
          style="background-color: white; box-shadow: none"
          @click="handleClickBack"
          >Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          class="button font-weight-bold next-btn font-family-raleway-medium"
          height="60"
          @click="handleClick"
        >
          next
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { collectionsService } from "@/services/collections";
import { CURRENCIES, PERIODS } from "@/utils";

export default {
  name: "SetBudget",
  data: () => ({
    setBudget: true,
    campaign: {},
    currency: "",
    checkPeriod: 0,
    itemCurrency: {
      USD: "US$",
    },
    periodItems: [
      { key: 0, value: "Daily" },
      { key: 1, value: "Weekly" },
      { key: 2, value: "Monthly" },
      { key: 3, value: "Total" },
    ],
    budgetRecommendations: [],
    budgetRecommend: {
      period: "",
      amout: 0,
      currency: "",
    },
    budgetOwn: {
      period: 0,
      amout: 10,
      currency: 0,
    },
  }),
  mounted() {
    localStorage.setItem("currentUrl", "set-budget");
  },
  async created() {
    const responseAccountBudgetRecommended = await collectionsService.get();
    this.budgetRecommendations = responseAccountBudgetRecommended?.data;
    this.budgetRecommend = responseAccountBudgetRecommended?.data[0];
  },
  methods: {
    handleClickPeriod(item) {
      this.budgetRecommend = {
        amout: item.amout,
        period: item.period,
        currency: item.currency,
      };
    },
    handleChangeBudget(val) {
      this.budgetOwn.period = val;
    },
    handleClickBack() {
      this.$router.push("approve-edit");
    },
    handleClick() {
      const campaign = localStorage.getItem("campaign");
      const parseToObj = JSON.parse(campaign);
      let budgetOptions = {};
      if (!this.setBudget) {
        budgetOptions = this.budgetOwn;
      } else {
        budgetOptions = this.budgetRecommend;
      }
      localStorage.setItem(
        "campaign",
        JSON.stringify({
          ...parseToObj,
          budget: {
            amout: parseInt(budgetOptions.amout),
            currency: budgetOptions.currency,
            period: budgetOptions.period,
          },
        })
      );
      localStorage.setItem("currentUrl", "review-company");
      this.$router.push("review-company");
    },
  },
  computed: {
    periods() {
      return PERIODS;
    },
    currencies() {
      return CURRENCIES;
    },
  }
};
</script>
<style lang="scss">
@import "../../assets/style/style.scss";
</style>
